<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";
import $ from "jquery";
import Cookies from "js-cookie";
// import moment from 'moment';
/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Tambah Permohonan Surat Masuk",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "SURAT MASUK",
      items: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "Surat Masuk",
          href: "/surat-masuk",
        },
        {
          text: "Tambah",
          active: true,
        },
      ],
      // variable Page Table
      loadingTable: true,
      dataTable: [],
      pagingTable: [],
      showDataToTable: "",
      showDataFromTable: "",
      showLampiran: false,
      totalDataTable: "",
      currentTablePage: "",
      searchDataTable: "",
      sifat_surat: ["Biasa", "Rahasia", "Sangat Rahasia"],
      kategori_surat: [],
      bentuk_dokumen: [
        "Surat",
        "Surat dan Proposal",
        "Surat dan Pendukung Lainnya",
      ],
      total_draft: "",
      total_inbox: "",
    
      // saved variable
      id: this.$route.params.id,

      // data detail
      ad_bentukdokumen: "",
      ad_departemen: "",
      ad_dikirim: "",
      ad_divisi: "",
      ad_duedate: "",
      ad_file_tindaklanjut: "",
      ad_id: "",
      ad_id_teamleader: "",
      ad_instansipengirim: "",
      ad_is_booking: "",
      ad_kategorisurat: "",
      ad_kategorisurat_code: "",
      ad_kategorisurat_id: "",
      ad_lampiran: "",
      ad_lampiran_db: "",
      ad_lampiran_password: "",
      ad_nomorsurat: "",
      ad_notelp: "",
      ad_perihal: "",
      ad_pic: "",
      ad_sifatsurat: "",
      ad_signer: "",
      ad_tandatangan: "",
      ad_tanggalsurat: "",
      ad_tanggalsuratditerima: "",
      ad_tindaklanjut: "",
      ad_tipesurat: "",
      ad_tsk_id: "",
      created_at: "",
      created_by: "",
      dari: "",
      kirim_date: "",
      status: "",
      status_message: "",
      updated_at: "",
      updated_by: "",
      timeline: [],
      barcode_link: '',
      //lampiran
      lampiran: [],
      lampiran_db: "",
      modalTeruskan: false,
      table_data: [],
      search: "",
      loadingTableUser: true,
      selected: [],
      isi_disposisi: "",
      user_password: "",
      modalShowPassword: false,
      showPasswordSurat: false,
      kategori_surat_selected: "",
      
      //lampiran
      draft: "y",
      is_edited: true,
      
      departemen_id: Cookies.get("session_departement_id"),
      role_slug: Cookies.get("session_role_slug"),
    };
  },
  mounted() {
    this.getSurat();
    this.getKategori();
    this.getSuratMasuk();
    this.getLampiran();
  },
  methods: {
    getSuratMasuk() {
      let self = this;
      self.loadingTable = true;
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
        allowOutsideClick: false,
      });

      axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
            "api/arsip-dokumen/suratmasuk/detail?i=" +
            self.id
        )
        .then((response) => {
          var response_data = response.data;
          //console.log(response_data)
          if (response_data.code == 200) {
            var arsip_dokumen = response_data.data.arsip_dokumen;
            self.ad_nomorsurat = arsip_dokumen.ad_nomorsurat;
            self.ad_tanggalsurat = arsip_dokumen.ad_tanggalsurat;
            self.ad_tanggalsuratditerima = arsip_dokumen.ad_tanggalsuratditerima;
            self.ad_tipesurat = arsip_dokumen.ad_tipesurat;
            self.ad_instansipengirim = arsip_dokumen.ad_instansipengirim;
            self.ad_sifatsurat = arsip_dokumen.ad_sifatsurat;
            self.ad_bentukdokumen = arsip_dokumen.ad_bentukdokumen;
            self.ad_kategorisurat = arsip_dokumen.ad_kategorisurat;
            self.ad_perihal = arsip_dokumen.ad_perihal;
            self.ad_duedate = arsip_dokumen.ad_duedate;
            self.ad_file_tindaklanjut = arsip_dokumen.ad_file_tindaklanjut;
            self.ad_lampiran = arsip_dokumen.ad_lampiran;
            self.ad_lampiran_db = arsip_dokumen.ad_lampiran;
            self.ad_lampiran_password = arsip_dokumen.ad_lampiran_password;
            self.status = arsip_dokumen.status;
            self.status_message = arsip_dokumen.status_message;
            self.total_draft = response_data.count_draft;

            // Kategori Surat
            axios
              .get(
                process.env.VUE_APP_BACKEND_URL_VERSION +
                  "/api/master/departemen?status=ENABLE&id="+arsip_dokumen.ad_kategorisurat_id
              )
              .then((response) => {
                var response_data = response.data;
                if (response_data.code == 200) {
                  self.kategori_surat_selected = response_data.list_data.data[0];
                } else {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: response_data.message,
                  });
                }
              });
            
            if(self.ad_bentukdokumen != 'surat'){
              self.showLampiran = true;
            }
            self.loadingTable = false;
            Swal.close();
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Gagal Load Data",
            });
          }
        });
    },
    getLampiran() {
      let self = this;
      self.loadingTable = true;
      axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
            "/api/arsip-dokumen/lampiran?id=" +
            self.id
        )
        .then((response) => {
          var response_data = response.data;
          if (response_data.code == 200) {
              let clear_data_json_lampiran = [];
              $.each(response_data.list_data, function (indexInArray, valueOfElement) { 
                clear_data_json_lampiran.push({
                  'file': valueOfElement.path,
                  'keterangan': valueOfElement.keterangan,
                  'password': valueOfElement.password,
                })
              });
              self.lampiran = clear_data_json_lampiran;
              self.lampiran_db = self.lampiran.length;
              self.loadingTable = false;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.message,
            });
          }
        });
    },
    getSurat() {
      let self = this;
      self.loadingTable = true;

      var kategori_id = self.kategori_selected?.id;
      if(kategori_id){
        kategori_id = self.kategori_selected?.id;
      }else{
        kategori_id = '';
      }

      axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
            "/api/arsip-dokumen/suratmasuk"
        )
        .then((response) => {
          var response_data = response.data;
          if (response_data.code == 200) {
            self.total_inbox = response_data.count_inbox;
            self.total_draft = response_data.count_draft;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.message,
            });
          }
        });
    },
    getKategori() {
      let self = this;
      axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "api/master/departemen?status=ENABLE&role_slug=" + self.role_slug + '&departement_id='+self.departemen_id
        )
        .then((response) => {
          var response_data = response.data;
          if (response_data.code == 200) {
            self.kategori_surat = response_data.list_data.data;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.message,
            });
          }
        });
    },
    uploadFile() {
      let self = this;
      if ($("#upload")[0].files[0]) {
        if ($("#upload")[0].files[0].size < 30000000) {
          $("#uploadLoading").html(
            '<span class="badge bg-warning p-1"><i class="fa fa-refresh fa-spin"></i> Loading...</span>'
          );
          var urlres = process.env.VUE_APP_BACKEND_URL_VERSION;

          var apiurl = process.env.VUE_APP_BACKEND_URL_VERSION + "api/arsip-dokumen/suratmasuk/uploadpdfwithqrcode";
          var FormData = require("form-data");
          var data = new FormData();
          data.append("file", $("#upload")[0].files[0]);
          data.append("q", 'yes');
          if(self.ad_sifatsurat == 'biasa' || self.ad_sifatsurat == ''){
            data.append("e", '');
          }else{
            data.append("e", 'yes');
          }
          data.append("t", 'masuk');
          var config = {
            method: "post",
            url: apiurl,
            headers: {
              Accept: "application/json",
            },
            data: data,
          };
          axios(config).then(function (response) {
            //console.log(response)
            var dir_name = response.data[0].file_upload.message.dir;
            urlres += dir_name;
            $("#uploadLoading").html(
              '<span class="badge bg-success p-1"><i class="fa fa-check"></i> Berhasil </span> <a href="' +
                urlres +
                '" target="_blank" style="padding-left:10px;"><span class="badge bg-success p-1"><i class="fa fa-eye"></i> Lihat File</span></a>'
            );
            self.ad_lampiran = urlres;
            self.ad_lampiran_password = response.data[0].password;
          }).catch((e) => {
            Swal.fire({
              icon: "error",
              title: "Gagal upload file",
              text: e.response?.data?.message?.file,
            });
          });
        }else{
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "File Upload Lebih Dari 30Mb",
          });
        }
      }
      
    },
    StoreData() {
        let self = this;

        var kategori_id = self.kategori_surat_selected?.id;
        if(kategori_id){
          kategori_id = self.kategori_surat_selected?.id;
        }else{
          kategori_id = '';
        }

        var password_lamp = self.ad_lampiran_password
        if(password_lamp){
          password_lamp = self.ad_lampiran_password
        }else{
          password_lamp = '';
        }

        Swal.fire({
            title: '<i class="fas fa-spinner fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
        });
        if(self.ad_perihal == ''){
          Swal.fire({
            icon: "warning",
            title: "Peringatan! ",
            text: "Perihal Surat Wajib Diisi",
          });
        }
        if(self.ad_tanggalsurat == '' || self.ad_nomorsurat == ''){
          Swal.fire({
            icon: "warning",
            title: "Peringatan! ",
            text: "Tanggal Surat dan Nomor Surat Wajib Diisi",
          });
        }else{
          var FormData = require("form-data");
          var data = new FormData();
          data.append("ad_id", self.id);
          data.append("ad_nomorsurat", self.ad_nomorsurat);
          data.append("ad_tanggalsuratditerima", self.ad_tanggalsuratditerima);
          data.append("ad_sifatsurat", self.ad_sifatsurat);
          data.append("ad_perihal", self.ad_perihal);
          data.append("ad_tanggalsurat", self.ad_tanggalsurat);
          data.append("ad_instansipengirim", self.ad_instansipengirim);
          data.append("ad_bentukdokumen", self.ad_bentukdokumen);
          data.append("ad_duedate", self.ad_duedate);
          data.append("ad_lampiran", self.ad_lampiran);
          data.append("ad_lampiran_password", password_lamp);
          data.append("ad_kategorisurat_id", kategori_id);
          data.append("id_user_session", Cookies.get("session_id"));
          data.append("jabatan_user", Cookies.get("session_jabatan"));
          data.append("departemen_user", Cookies.get("session_departemen"));
          data.append("draft", self.draft);
          data.append("ad_tipesurat", "Surat Masuk");
          data.append("is_edited", true);
          data.append("lampiran_file", JSON.stringify(self.lampiran));

          var config = {
              method: "post",
              url:
              process.env.VUE_APP_BACKEND_URL_VERSION + "/api/arsip-dokumen/suratmasuk/store",
              data: data,
          };
          axios(config)
              .then(function (response) {
              var response_data = response.data; 
              //console.log(response);
              if(response_data.code != 200){
                  Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  html: response_data.message,
                  });
              }else{
                  let timerInterval;
                  Swal.fire({
                      icon: "success",
                      title: "Berhasil",
                      text: "Anda akan diarahkan ke halaman arsip dokumen surat masuk segera",
                      timer: 2000,
                      timerProgressBar: true,
                      showCancelButton: false,
                      showConfirmButton: false,
                      willClose: () => {
                          clearInterval(timerInterval);
                      },
                  }).then((result) => {
                  /* Read more about handling dismissals below */
                  if (result.dismiss === Swal.DismissReason.timer) {
                      // self.$router.push({ name: "vendor" });
                      Swal.close();
                      self.showModal = false;
                      self.last_inserted_id = response_data.id;
                      self.$router.push({ name: "all-surat_masuk", params: { token: self.last_inserted_id } });
                  }
                  });
              }
              })
              .catch((e) => {
              this.axiosCatchError = e.response.data.data;
              Swal.close();
          });
        }
    },

    draftClick(){
      let self = this;
      self.draft = 'y';
      $("#submit-button").trigger('click');
    },
    
    kirimClick(){
      let self = this;
      self.draft = 'n';
      $("#submit-button").trigger('click');
    },

    // penugasan
    addlampiran() {
      this.lampiran.push({
        file: '',
        keterangan: '',
        password: '',
      });
    },
    removelampiran(index) {
      this.lampiran.splice(index, 1);
    },

    uploadFileLampiran(row) {
      let self = this;
      if ($("#upload_file_lampiran"+row+"")[0].files[0]) {
        if ($("#upload_file_lampiran"+row+"")[0].files[0].size < 30000000) {
          $("#upload_loading_file_lampiran"+row+"").html(
            '<span class="badge bg-warning p-1"><i class="fa fa-refresh fa-spin"></i> Loading...</span>'
          );

          var urlres = process.env.VUE_APP_BACKEND_URL_VERSION;

          var apiurl = process.env.VUE_APP_BACKEND_URL_VERSION + "api/arsip-dokumen/suratmasuk/uploadpdfwithqrcode";
          var FormData = require("form-data");
          var data = new FormData();
          data.append("file", $("#upload_file_lampiran"+row+"")[0].files[0]);
          data.append("q", 'yes');
          if(self.ad_sifatsurat == 'biasa' || self.ad_sifatsurat == ''){
            data.append("e", '');
          }else{
            data.append("e", 'yes');
          }
          data.append("t", 'masuk');
          var config = {
            method: "post",
            url: apiurl,
            headers: {
              Accept: "application/json",
            },
            data: data,
          };
          axios(config).then(function (response) {
            //console.log(response);
            var dir_name = response.data[0].file_upload.message.dir;
            urlres += dir_name;
            $("#upload_loading_file_lampiran"+row+"").html(
              '<span class="badge bg-success p-1"><i class="fa fa-check"></i> Berhasil </span> <a href="' +
                urlres +
                '" target="_blank" style="padding-left:10px;"><span class="badge bg-success p-1"><i class="fa fa-eye"></i> Lihat File</span></a>'
            );

            self.lampiran[row].file = urlres;
            self.lampiran[row].password = response.data[0].password;
          }).catch((e) => {
            Swal.fire({
              icon: "error",
              title: "Gagal upload file",
              text: e.response?.data?.message?.file,
            });
          });
        }else{
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "File Upload Lebih Dari 30Mb",
          });
        }
      }
    },

    bentukDokumenSelected(){
      let self = this;
      if(self.ad_bentukdokumen != 'surat'){
        self.showLampiran = true;
      }else{
        self.showLampiran = false;
      }
    },

    downloadHandle() {
      let self = this;
      const link = document.createElement("a");
      link.setAttribute('target', '_blank');
      var urlres = process.env.VUE_APP_BACKEND_URL_VERSION;
      urlres += self.ad_lampiran;
      link.href = urlres;
      link.click();
    },

    downloadEachAttachment(url) {
      const link = document.createElement("a");
      link.setAttribute('target', '_blank');
      var urlres = process.env.VUE_APP_BACKEND_URL_VERSION;
      urlres += url;
      link.href = urlres;
      link.click();
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row mb-2">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
        <ul class="list-group list-group-horizontal" v-if="role_slug == 'officer' || role_slug == 'team_leader' || role_slug == 'kepala_divisi'">
          <li class="col-md-6 list-group-item fs-5">
            <router-link :to="{ name: 'surat-masuk-didisposisikan' }" class="text-dark">
              <i class="bx bx-check-circle"> </i> Sudah Disposisi
            </router-link>
          </li>
          <li class="col-md-6 list-group-item fs-5">
            <router-link :to="{ name: 'surat-masuk-ditindaklanjuti' }" class="text-dark">
              <i class="bx bx-check-double fs-5"> </i> Sudah Ditindaklanjuti
            </router-link>
          </li>
        </ul>
        <ul class="list-group list-group-horizontal" v-else>
          <li class="col-md-3 list-group-item fs-5">
            <router-link :to="{ name: 'surat-masuk-draft' }" class="text-dark">
              <i class="bx bx-list-ol fs-5"> </i> Draft
              <span class="badge bg-primary rounded-pill">{{ total_draft }}</span>
            </router-link>
          </li>
          <li class="col-md-3 list-group-item fs-5">
            <router-link :to="{ name: 'surat-masuk-diajukan' }" class="text-dark">
              <i class="bx bx-send"> </i> Menunggu Disposisi
            </router-link>
          </li>
          <li class="col-md-3 list-group-item fs-5">
            <router-link :to="{ name: 'surat-masuk-didisposisikan' }" class="text-dark">
              <i class="bx bx-check-circle"> </i> Sudah Disposisi
            </router-link>
          </li>
          <li class="col-md-3 list-group-item fs-5">
            <router-link :to="{ name: 'surat-masuk-ditindaklanjuti' }" class="text-dark">
              <i class="bx bx-check-double fs-5"> </i> Sudah Ditindaklanjuti
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-form class="p-2" @submit.prevent="StoreData">
              <div class="row">
                <div class="col-md-6">
                  <b-form-group class="mb-3">
                    <label>Nomor Surat <span class="text-danger">* Wajib diisi</span></label>
                    <b-form-input type="text" v-model="ad_nomorsurat"></b-form-input>
                  </b-form-group>
                  <b-form-group class="mb-3" label="Tanggal Surat Diterima">
                    <b-form-input type="date" v-model="ad_tanggalsuratditerima"></b-form-input>
                  </b-form-group>
                  <b-form-group class="mb-3" label="Sifat Surat">
                    <select class="form-control input-sm" v-model="ad_sifatsurat">
                      <option value="">-Pilih Sifat Surat-</option>
                      <option value="biasa">Biasa</option>
                      <option value="rahasia">Rahasia</option>
                      <option value="sangat_rahasia">
                        Sangat Rahasia
                      </option>
                    </select>
                  </b-form-group>
                  <b-form-group class="mb-3">
                    <label>Kategori Surat <span class="text-danger">* Wajib diisi</span></label>
                    <v-select
                      placeholder="-Pilih Kategori Surat-"
                      :options="kategori_surat"
                      label="nama"
                      v-model="kategori_surat_selected"
                    ></v-select>
                  </b-form-group>
                  <b-form-group class="mb-3">
                    <label>Perihal Surat <span class="text-danger">* Wajib diisi</span></label>
                    <textarea v-model="ad_perihal" class="form-control" cols="30" rows="10"></textarea>
                  </b-form-group>
                  <b-form-group class="mb-3" label="Attachment">
                    <input type="file" class="form-control" id="upload" @change="uploadFile" accept="application/pdf"/>
                    <input type="hidden" v-model="ad_lampiran">
                    <div class="respond-input-file float-left" id="uploadLoading">
                      <div v-if="ad_lampiran_db" class="btn btn-info btn-sm mt-1" @click="downloadHandle()">
                        <i class="fa fa-download"></i> Download
                      </div>
                      <span v-if="ad_lampiran_password"> Password: {{ ad_lampiran_password }}</span>
                    </div>
                    <p class="help-block" style="color: red">
                      File yang diinputkan harus .pdf dan Max 30MB
                    </p>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group class="mb-3">
                    <label>Tanggal Surat <span class="text-danger">* Wajib diisi</span></label>
                    <b-form-input type="date" v-model="ad_tanggalsurat"></b-form-input>
                  </b-form-group>
                  <b-form-group class="mb-3" label="Instansi Pengirim">
                    <b-form-input type="text" v-model="ad_instansipengirim"></b-form-input>
                  </b-form-group>
                  <b-form-group class="mb-3" label="Bentuk Dokumen">
                    <select class="form-control input-sm" v-model="ad_bentukdokumen" @change="bentukDokumenSelected">
                      <option value="">-Pilih Bentuk Dokumen-</option>
                      <option value="surat">Surat</option>
                      <option value="surat_dan_proposal">
                        Surat dan Proposal
                      </option>
                      <option value="surat_dan_dokumen_pendukung_lainnya">
                        Surat dan Pendukung Lainnya
                      </option>
                    </select>
                  </b-form-group>
                  <b-form-group class="mb-3" label="Due Date">
                    <b-form-input type="date" v-model="ad_duedate"></b-form-input>
                  </b-form-group>
                  <input type="hidden" class="form-control" v-model="draft">
                </div>
              </div>
              <div class="row" v-if="showLampiran">
                <div class="col-md-12">
                  <table
                    class="table mb-0 table-bordered table-condensed table-hover w-full mb-2"
                  >
                    <thead>
                      <tr class="bg-light">
                        <th colspan="4">Lampiran</th>
                      </tr>
                      <tr class="bg-light">
                        <th>#</th>
                        <th>File</th>
                        <th>Keterangan</th>
                        <th class="text-end">
                          <div
                            class="btn btn-success btn-sm"
                            v-on:click="addlampiran()"
                          >
                            <i class="bx bx-plus"></i> Tambah Lampiran
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="lampiran.length == 0">
                        <td colspan="4">TIdak Ada Data</td>
                      </tr>
                      <tr v-for="(item, index) in lampiran" :key="index">
                        <td>#</td>
                        <td>
                          <input type="file" class="form-control" :id="'upload_file_lampiran'+index" @change="uploadFileLampiran(index)" accept="application/pdf">
                          <input type="hidden" v-model="item.file">
                          <div v-if="lampiran_db >= index + 1">
                            <div class="respond-input-file float-left" :id="'upload_loading_file_lampiran'+index">
                              <div class="btn btn-info btn-sm mt-1" :id="'file_lampiran'+index" @click="downloadEachAttachment(item.file)"><i class="fa fa-eye"></i> Lihat File</div>
                              <span> Password: {{ item.password }}</span>
                            </div>
                          </div>
                          <div v-else>
                            <div class="respond-input-file float-left" :id="'upload_loading_file_lampiran'+index"></div>
                          </div>
                        </td>
                        <td>
                          <input
                            type="text"
                            class="form-control"
                            v-model="item.keterangan"
                          />
                          <input
                            type="hidden"
                            class="form-control"
                            v-model="item.password"
                          />
                        </td>
                        <td class="text-center">
                          <button
                            type="button"
                            class="btn btn-danger btn-sm"
                            @click="removelampiran(index)"
                          >
                            <i class="fa fa-minus"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <router-link
                    :to="{ name: 'all-surat_masuk' }"
                    class="btn btn-secondary btn-sm text-white m-1"
                  >
                    <i class="fa fa-chevron-left"></i> Kembali Ke List Surat
                  </router-link>
                </div>
                <div class="col-md-6">
                  <div class="text-end">
                    <div v-if="status=='draft'" class="btn btn-info m-1" v-on:click="draftClick">
                      <i class="fa fa-save"></i> Draft
                    </div>
                    <!-- <div v-if="ad_lampiran" class="btn btn-primary m-1"  v-on:click="kirimClick">
                      <i class="fa fa-save"></i> Simpan
                    </div> -->
                    <div class="btn btn-primary m-1"  v-on:click="kirimClick">
                      <i class="fa fa-save"></i> Simpan
                    </div>
                  </div>
                </div>
              </div>
              <button type="submit" style="display:none" id="submit-button">Submit</button>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
